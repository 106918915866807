<template>
  <v-app dark>
    <v-navigation-drawer light class="white" v-model="drawer" temporary fixed app>
      <v-list>
        <v-list-item class="pl-6 mb-6 mt-4 text-center">
          <v-list-item-content>
            <v-list-item-title>
              <router-link to="/" style="text-decoration: none">
                <span class="logo-text"><span class="secondary--text">Tran</span>Save</span>
              </router-link>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item color="primary" class="mb-1" v-for="(item, i) in items" :key="i" :to="item.to" exact>
          <v-list-item-action color="grey darken-2">
            <v-icon>{{item.icon}}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="text-caption" v-text="item.title" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar fixed app tile flat class="grey lighten-5 nav-bar" :class="{ 'px-12':$vuetify.breakpoint.mdAndUp }" v-if="$route.name != 'dashboard-accounts'">
      <v-app-bar-nav-icon color="primary" @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-toolbar-title class="text-button primary--text">Transave Wallet</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="(notification = !notification), notif()"><v-icon class="primary--text">mdi-bell</v-icon><v-badge overlap color="red" bordered dot v-if="notifications != ''"></v-badge></v-btn>
      <v-menu offset-y>
      <template v-slot:activator="{ on, acountMenu }">
        <v-avatar 
          v-bind="acountMenu"
          v-on="on"
         class="ml-4 av" size="40">
        <v-img :src="user ? user.image : require('../assets/icons/user.png')" :lazy-src="require('../assets/icons/user.png')"></v-img>
      </v-avatar>
      </template>
      <v-list dense nav>
        <v-list-item class="mb-3">
          <v-list-item-avatar>
          <v-img
              :src="user ? user.image : require('../assets/icons/user.png')" :lazy-src="require('../assets/icons/user.png')"
            ></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="text-body-1">{{ (user && user.name) ? user.name.split(' ').slice(0, -1).join(' ') + " " + user.name.split(' ').slice(-1).join(' ') : '' }}</v-list-item-title>
            <v-list-item-subtitle class="text-caption">{{ user ? user.email : '' }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/dashboard/accounts" color="primary">
          <v-list-item-action>
            <v-icon size="20">mdi-account</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Account Settings</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="logout()" color="primary">
          <v-list-item-action>
            <v-icon size="20">mdi-exit-to-app</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
      
    </v-app-bar>
    <v-navigation-drawer light right class="white" v-model="notification" temporary fixed app>
      <v-list dense class="pt-0">
        <v-list-item class="text-center blue lighten-5">
          <v-list-item-content>
            <v-list-item-title>
              What's new on Transave
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action @click="(notification = !notification), notif()">
            <v-btn icon><v-icon>mdi-close</v-icon></v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-container v-if="isLoading" class="loading">
        <mini-loader class="text-center"></mini-loader>
      </v-container>
      <div v-if="!isLoading">
        <v-container class="pa-2" v-if="notifications != '' && !isLoading">
          <v-card outlined class="mb-3 pa-3">
            <div class="text-overline primary--text font-weight-medium">
              May 27 2021
            </div>
            <div class="text-caption">
              Joshua requested to join God's Hand Cooperative Savings
            </div>
            <div><v-btn block class="secondary mt-3" small depressed>Accept</v-btn></div>
          </v-card>
          <v-card outlined class="mb-3 pa-3">
            <div class="text-overline primary--text font-weight-medium">
              May 12 2021
            </div>
            <div class="text-caption">
              Chizitere invited you to join South-South future Savings
            </div>
            <div><v-btn block class="secondary mt-3" small depressed>Join</v-btn></div>
          </v-card>
          <v-card outlined class="mb-3 pa-3">
            <div class="text-overline primary--text font-weight-medium">
              May 14 2021
            </div>
            <div class="text-caption">
              You recieved #5,000 to your Transave Wallet
            </div>
          </v-card>
        </v-container>
        <v-container class="text-center loading" v-else>
          <div class="text-caption text--disabled">Horray! no notifications here</div>
        </v-container>
      </div>
      <!--template v-slot:append>
        <div class="pa-2" v-if="notifications != ''">
          <v-btn block color="primary" dark depressed>
            Clear All
          </v-btn>
        </div>
      </template-->
    </v-navigation-drawer>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import MiniLoader from '../components/MiniLoader.vue'
export default {
  components: { MiniLoader },
  data() {
    return {
    
   clipped: false,
      drawer: false,
      fixed: false,
      items: [
        {
          icon: 'mdi-apps',
          img: require('@/assets/icons/dashboard.png'),
          title: 'Dashboard',
          to: '/dashboard',
        },
        {
          icon: 'mdi-bank-transfer',
          img: require('@/assets/icons/payment.png'),
          title: 'Fund Transfer',
          to: '/dashboard/fund',
        },
        {
          icon: 'mdi-circle-double',
          img: require('@/assets/icons/payment.png'),
          title: 'Remittance',
          to: '/dashboard/remittance',
        },
        {
          icon: 'mdi-cash-refund',
          img: require('@/assets/icons/bill.png'),
          title: 'Bills/Payment',
          to: '/dashboard/bills',
        },
        {
          icon: 'mdi-calculator',
          img: require('@/assets/icons/pos-terminal.png'),
          title: 'POS',
          to: '/dashboard/pos',
        },
        {
          icon: 'mdi-wallet-outline',
          img: require('@/assets/icons/loan.png'),
          title: 'Loan',
          to: '/dashboard/loan',
        },
        {
          icon: 'mdi-account-cash-outline',
          img: require('@/assets/icons/save-money.png'),
          title: 'Save',
          to: '/dashboard/savings',
        },
        {
          icon: 'mdi-briefcase-account-outline',
          img: require('@/assets/icons/briefcase.png'),
          title: 'Business',
          to: '/dashboard/business',
        },
        {
          icon: 'mdi-credit-card-outline',
          img: require('@/assets/icons/credit-card.png'),
          title: 'Cards',
          to: '/dashboard/cards',
        },
        {
          icon: 'mdi-history',
          img: require('@/assets/icons/transaction.png'),
          title: 'Transactions',
          to: '/dashboard/transactions',
        }
      ],
      miniVariant: false,
      right: true,
      rightDrawer: false,
      title: 'Vuetify.js',
      notification: false,
      notifications: [],
      isLoading: true,
    }
  },

  methods: {
    logout() {
      this.$store.dispatch('auth/logout', { root: true} )
    },

    getNotifications() {
      
    },

    notif() {
      if(this.notification){
        setTimeout(() => {
          this.isLoading = false
        }, 2000)
      } else {
        this.isLoading = true
      }
    }
  },

  mounted() {
    this.getNotifications()
  },

  computed: {
    ...mapGetters('auth', ['user']),
  },
}
</script>

<style lang="scss" scoped>
.logo-text {
  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 25px !important;
  line-height: 33px !important;
}


.nav-bar {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
 }

.v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
    box-shadow: none !important;
  /*   border-bottom: 1px solid rgb(238, 238, 238) !important;
       box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.1), 0px 4px 5px 0px rgba(0, 0, 0, 0.04), 0px 1px 10px 0px rgba(0, 0, 0, 0.02) !important;
*/
}
.av{
  cursor: pointer;
}
.outlined {
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.loading {
  height: calc(100vh);
  position: absolute;
  display: block;
  width: 100%;
  top: 40%;
}
</style>